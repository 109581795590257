import React, { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  Table,
  Button,
  Modal,
  Space,
  Input,
  Checkbox,
  Tooltip,
  DatePicker,
} from "antd";
import { getAllApplications } from "api/applications";
import { getNaif } from "helper/EncrptionUtils";
import { getId } from "helper/EncrptionUtils";
import { getApplicationByBdmId } from "api/applications";
import { useNavigate } from "react-router-dom";
import TabModal from "./TabModal";
import { NaifContext } from "context/NaifContext";
import Papa from "papaparse";
import { getOnePagerData } from "api/applications";
const { RangePicker } = DatePicker;

const documentColumns = [
  {
    title: "File Name",
    dataIndex: "fileName",
    key: "fileName",
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Button
          type="link"
          onClick={() => window.open(record.fileUrl, "_blank")}
        >
          View
        </Button>
      </Space>
    ),
  },
];

const FormDataTable = () => {
  const naifContext = useContext(NaifContext);
  const { inEditNaif, setInEditNaif } = naifContext;
  const [visible, setVisible] = useState(false);
  const selectedRecordRef = useRef(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [allData, setAllData] = useState([]); // Stores the full dataset
  const [filteredData, setFilteredData] = useState([]); // Stores the filtered dataset
  const [searchValues, setSearchValues] = useState({
    company_name: "",
    email: "",
    contact: "",
    scheme_name: [],
    bdm_name: [],
    dateRange: [], // Add dateRange to searchValues state
  });

  const [bdmOptions, setBdmOptions] = useState([]); // Unique BDM names for checkbox
  const [customerType, setCustomerType] = useState([]);
  const [schemeOptions, setSchemeOptions] = useState([]); // Unique scheme names for checkbox
  const [status, setStatus] = useState([]);
  let navigate = useNavigate();
  // Fetch Applications and Initialize Data
  const fetchApplications = async () => {
    try {
      const data =
        getNaif() === "Admin"
          ? await getAllApplications()
          : getNaif() === "OnePager"
          ? await getOnePagerData()
          : await getApplicationByBdmId(getId());

      const applications = data.result;

      setAllData(applications); // Save full dataset
      setFilteredData(applications); // Initialize filtered data

      // Extract unique BDM names
      const uniqueBdmNames = Array.from(
        new Set(applications.map((item) => item.bdm_name))
      ).filter(Boolean); // Remove empty/null values
      setBdmOptions(uniqueBdmNames);

      const uniquecustomerType = Array.from(
        new Set(applications.map((item) => item.customerType))
      ).filter(Boolean); // Remove empty/null values
      setCustomerType(uniquecustomerType);

      const uniqueStatus = Array.from(
        new Set(applications.map((item) => item.status))
      ).filter(Boolean); // Remove empty/null values
      setStatus(uniqueStatus);

      // Extract unique scheme names
      const uniqueSchemeNames = Array.from(
        new Set(applications.flatMap((item) => item.scheme_name))
      ).filter(Boolean); // Remove empty/null values
      setSchemeOptions(uniqueSchemeNames);
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  };

  useEffect(() => {
    fetchApplications();
  }, []);

  const formatNumberToIndianLocale = (number) => {
    if (number === null || number === undefined) return "-";
    return new Intl.NumberFormat("en-IN").format(number);
  };

  // Handle text search filters
  const handleTextSearch = (value, column) => {
    setSearchValues((prev) => {
      const newValues = { ...prev, [column]: value };
      filterData(newValues);
      return newValues;
    });
  };

  // Handle checkbox filters
  const handleCheckboxChange = (value, column) => {
    setSearchValues((prev) => {
      const newValues = { ...prev, [column]: value };
      filterData(newValues);
      return newValues;
    });
  };

  const downloadCSV = () => {
    const csvData = filteredData.map((item) => ({
      "Created At": formatDate(item.createdAt),
      "Company Name": item.company_name,
      Email: item.email,
      "Contact No": item.contact,
      "Executioner Name": item.Executioner,
      "Project Size": item.project_size,
      "Scheme Name": item.scheme_name.join(", "),
      "Commercial (%)": item.commercial,
      Remarks: item.remarks,
      "BDM Name": item.bdm_name,
      "Customer Type": item.customerType,
      Status: item.status,
    }));

    const csv = Papa.unparse(csvData); // Convert JSON to CSV
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = "application_data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to filter data
  const filterData = (searchValues) => {
    const filtered = allData.filter((item) => {
      const matchesCompanyName = searchValues.company_name
        ? item?.company_name
            ?.toLowerCase()
            ?.includes(searchValues?.company_name?.toLowerCase())
        : true;
      const matchesExecutioner = searchValues.Executioner
        ? item?.Executioner?.toLowerCase()?.includes(
            searchValues?.Executioner?.toLowerCase()
          )
        : true;
      const matchesEmail = searchValues.email
        ? item?.email
            ?.toLowerCase()
            ?.includes(searchValues?.email?.toLowerCase())
        : true;
      const matchesContact = searchValues.contact
        ? item?.contact?.includes(searchValues?.contact)
        : true;
      const matchesSchemeName = searchValues.scheme_name?.length
        ? item.scheme_name.some((scheme) =>
            searchValues.scheme_name.includes(scheme)
          )
        : true;
      const matchesBdmName = searchValues.bdm_name?.length
        ? searchValues.bdm_name.includes(item.bdm_name)
        : true;
      const matchescustomerType = searchValues.customerType?.length
        ? searchValues.customerType.includes(item.customerType)
        : true;

      const matchStatus = searchValues.status?.length
        ? searchValues.status.includes(item.status)
        : true;

      return (
        matchesCompanyName &&
        matchesEmail &&
        matchesContact &&
        matchesSchemeName &&
        matchesBdmName &&
        matchStatus &&
        matchesExecutioner &&
        matchescustomerType
      );
    });

    setFilteredData(filtered);
  };

  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  // Modal handling
  const showDocuments = (documents) => {
    setSelectedDocuments(documents);
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleEdit = (record) => {
    console.log("Previous record:", selectedRecordRef.current);
    setSelectedRecord(record);
    selectedRecordRef.current = record; // Track the current record
    setEditModalVisible(true);
  };

  const handleModalClose = () => {
    setEditModalVisible(false); // Close the modal first
    setTimeout(() => {
      setSelectedRecord(null); // Clear the selected record after closing
    }, 300); // Use a small delay if needed for better UX
  };

  useEffect(() => {
    if (!getNaif()) {
      navigate("/");
    }
  }, []);

  // Table columns
  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <span>{formatDate(text)}</span>,
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      filterDropdown: () => (
        <Input
          placeholder="Search by Company Name"
          value={searchValues.company_name}
          onChange={(e) => handleTextSearch(e.target.value, "company_name")}
          className="p-2"
        />
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      filterDropdown: () => (
        <Input
          placeholder="Search by Email"
          value={searchValues.email}
          onChange={(e) => handleTextSearch(e.target.value, "email")}
          className="p-2"
        />
      ),
    },
    {
      title: "Contact No",
      dataIndex: "contact",
      key: "contact",
      filterDropdown: () => (
        <Input
          placeholder="Search by Contact No"
          value={searchValues.contact}
          onChange={(e) => handleTextSearch(e.target.value, "contact")}
          className="p-2"
        />
      ),
    },
    {
      title: "Executioner Name",
      dataIndex: "Executioner",
      key: "Executioner",
      filterDropdown: () => (
        <Input
          placeholder="Search by Executioner Name"
          value={searchValues.Executioner}
          onChange={(e) => handleTextSearch(e.target.value, "Executioner")}
          className="p-2"
        />
      ),
    },
    {
      title: "Project Size",
      dataIndex: "project_size",
      key: "project_size",
      render: (_, record) => formatNumberToIndianLocale(record.project_size),
    },
    {
      title: "Scheme Name",
      dataIndex: "scheme_name",
      key: "scheme_name",
      render: (scheme_name) => scheme_name.join(", "),
      filterDropdown: () => (
        <Checkbox.Group
          options={schemeOptions}
          value={searchValues.scheme_name}
          onChange={(value) => handleCheckboxChange(value, "scheme_name")}
          className="flex max-h-40 flex-col space-y-2 overflow-y-auto p-3"
        />
      ),
    },
    {
      title: "Commercial (%)",
      dataIndex: "commercial",
      key: "commercial",
    },
    {
      title: "Final Calculation",
      key: "calculated_value_1",
      render: (_, record) =>
        record.project_size && record.commercial
          ? formatNumberToIndianLocale(
              (record.project_size * record.commercial) / 100
            )
          : "-",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (remarks) => (
        <Tooltip title={remarks}>
          <span>
            {remarks.length > 20 ? `${remarks.slice(0, 20)}...` : remarks}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "BDM Name",
      dataIndex: "bdm_name",
      key: "bdm_name",
      filterDropdown: () => (
        <Checkbox.Group
          options={bdmOptions}
          value={searchValues.bdm_name}
          onChange={(value) => handleCheckboxChange(value, "bdm_name")}
          className="flex max-h-40 flex-col space-y-2 overflow-y-auto p-3"
        />
      ),
    },
    {
      title: "Customer Type",
      dataIndex: "customerType",
      key: "customerType",
      filterDropdown: () => (
        <Checkbox.Group
          options={customerType}
          value={searchValues.customerType}
          onChange={(value) => handleCheckboxChange(value, "customerType")}
          className="flex max-h-40 flex-col space-y-2 overflow-y-auto p-3"
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filterDropdown: () => (
        <Checkbox.Group
          options={status}
          value={searchValues.status}
          onChange={(value) => handleCheckboxChange(value, "status")}
          className="flex max-h-40 flex-col space-y-2 overflow-y-auto p-3"
        />
      ),
    },
    {
      title: "Documents",
      key: "documents",
      width: 150,
      render: (_, record) => (
        <Button type="primary" onClick={() => showDocuments(record.documents)}>
          View Documents
        </Button>
      ),
    },
    {
      title: "Edit",
      key: "edit",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() => {
            handleEdit(record);
            setInEditNaif(record);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div className="p-4">
      {getNaif() === "Admin" && (
        <Button
          type="primary"
          className=""
          style={{ marginBottom: 16 }}
          onClick={downloadCSV}
        >
          Download CSV
        </Button>
      )}
      <Table
        dataSource={filteredData}
        columns={columns}
        pagination={true}
        rowKey="key"
        scroll={{ y: 600, x: 2000 }}
        className="font-semibold"
      />

      <Modal
        key={selectedRecord?.id || "new"}
        title="Application Details"
        visible={editModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={1000}
      >
        {selectedRecord ? (
          <TabModal record={selectedRecord} />
        ) : (
          <p>Loading...</p>
        )}
      </Modal>

      {/* Modal for displaying documents */}
      <Modal
        title="Uploaded Documents"
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        width={800}
      >
        <Table
          columns={documentColumns}
          dataSource={selectedDocuments}
          pagination={false}
          rowKey="fileName"
          scroll={{ y: 300 }}
          style={{ maxHeight: "600px", overflowY: "auto" }}
        />
      </Modal>
    </div>
  );
};

export default FormDataTable;
