import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, InputNumber, Select, message, Radio } from "antd";
import { NaifContext } from "context/NaifContext";
import { updateApplication } from "api/applications";
import { getId } from "helper/EncrptionUtils";
import { TimePicker } from "antd";
import moment from "moment";
import { allUser } from "api/users";
import { allBm } from "api/users";
const { Option } = Select;

const EditDetails = ({ record }) => {
  const [form] = Form.useForm();
  const naifContext = useContext(NaifContext);
  const { inEditNaif, setInEditNaif } = naifContext;
  const [user, setUser] = useState([]);
  const [bm, setBM] = useState([]);
  const bdm_id = getId();
  const schemes = [
    "NAIF",
    "Working Capital",
    "HL",
    "LAP",
    "AHIDF",
    "NLM",
    "Machinery Loan",
    "Others",
  ];

  // Watch commercial and project_size dynamically
  const commercial = Form.useWatch("commercial", form);
  const project_size = Form.useWatch("project_size", form);
  const selectedSchemes = Form.useWatch("scheme_name", form);
  // Define input style
  const inputStyle = {
    backgroundColor: "#f5f5f5",
    border: "1px solid #d9d9d9",
    borderRadius: "4px",
    padding: "8px",
    width: "100%",
  };

  const handleSave = async (values) => {
    const formattedTalktime = values.talktime
      ? values.talktime.format("HH:mm:ss") // Convert Moment to string
      : null;

    const updatedValues = {
      ...values,
      talktime: formattedTalktime, // Replace Moment object with string
    };

    try {
      const response = await updateApplication(record.id, {
        values: updatedValues,
        bdm_id: bdm_id,
      });
      if (response.success) {
        message.success(response.message);
      } else {
        message.error(response.message);
      }
    } catch (error) {
      console.log("Error updating:", error);
    }
  };

  const naifAllUser = async () => {
    try {
      const users = await allUser();
      if (users.success) {
        setUser(users.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getBM = async () => {
    try {
      const users = await allBm();
      if (users.success) {
        setBM(users.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    naifAllUser();
    getBM();
  }, []);

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        customerType: record?.customerType,
        company_name: record?.company_name,
        email: record?.email,
        contact: record?.contact,
        project_size: record?.project_size,
        scheme_name: record?.scheme_name,
        commercial: record?.commercial,
        remarks: record?.remarks,
        custom_scheme: record?.custom_scheme,
        Executioner: record?.Executioner,
        talktime: record?.talktime ? moment(record.talktime, "HH:mm:ss") : null,
        bm_id: record?.branch_manager,
        closer_id: record?.closer_by,
      }}
      onFinish={handleSave}
    >
      {/* Radio Buttons for Customer Type */}
      <div className=" grid grid-cols-2 ">
        <Form.Item
          name="customerType"
          label="Customer Type"
          rules={[{ required: true, message: "Please select a customer type" }]}
        >
          <Radio.Group>
            <Radio value="Existing">Existing Customer</Radio>
            <Radio value="New">New Customer</Radio>
          </Radio.Group>
        </Form.Item>

        {/* <Form.Item name="talktime" className=" px-2">
          <label>Talk Time :</label>
          <TimePicker
            name="talktime"
            format="HH:mm:ss" // Format for hours, minutes, and seconds
            placeholder="Select talktime"
            style={{ width: "100%" }}
            value={form.getFieldValue("talktime")} // Controlled value from form
            onChange={(time) => {
              form.setFieldsValue({ talktime: time }); // Update form state on time change
            }}
          />
        </Form.Item> */}
      </div>
      <div className="grid grid-cols-2 space-x-2">
        {/* Company Name */}
        <Form.Item
          name="company_name"
          label="Company Name"
          rules={[{ required: true, message: "Company Name is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="Executioner" label="Executioner Name">
          <Input />
        </Form.Item>

        {/* Email */}
        <Form.Item
          name="email"
          label="Email"
          rules={[{ type: "email", message: "Please enter a valid email" }]}
        >
          <Input />
        </Form.Item>

        {/* Contact Number */}
        <Form.Item
          name="contact"
          label="Contact Number"
          rules={[{ required: true, message: "Contact Number is required" }]}
        >
          <Input />
        </Form.Item>

        {/* Project Size */}
        <Form.Item
          name="project_size"
          label="Project Size"
          rules={[{ required: true, message: "Project Size is required" }]}
        >
          <InputNumber
            placeholder="Enter project size"
            style={{ width: "100%" }}
            formatter={(value) =>
              value ? new Intl.NumberFormat("en-IN").format(value) : ""
            }
            parser={(value) => (value ? value.replace(/,/g, "") : "")}
          />
        </Form.Item>

        {/* Commercial */}
        <Form.Item
          name="commercial"
          label="Commercial (%)"
          // rules={[{ required: true, message: "Commercial is required" }]}
        >
          <InputNumber
            placeholder="Enter commercial percentage"
            style={{ width: "100%" }}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace("%", "")}
          />
        </Form.Item>

        {/* Projection Size * Commercial */}
        {/* <Form.Item label="Projection Size * Commercial">
          <Input
            readOnly
            placeholder="Projection Size * Commercial"
            value={
              commercial && project_size
                ? new Intl.NumberFormat("en-IN").format(
                    (commercial * project_size) / 100
                  )
                : ""
            }
            style={inputStyle}
          />
        </Form.Item> */}

        {/* Advanced Payment */}
        {/* <Form.Item label="Advanced Payment (50-50)">
          <Input
            readOnly
            placeholder="Advanced Payment (50-50)"
            value={
              commercial && project_size
                ? new Intl.NumberFormat("en-IN").format(
                    (commercial * project_size) / 100 / 2
                  )
                : ""
            }
            style={inputStyle}
          />
        </Form.Item> */}

        {/* Scheme Name */}
        <Form.Item
          name="scheme_name"
          label="Scheme Name"
          rules={[{ required: true, message: "Please select a scheme" }]}
        >
          <Select
            mode="multiple"
            placeholder="Select scheme(s)"
            style={{ width: "100%" }}
          >
            {schemes.map((scheme, index) => (
              <Option key={index} value={scheme}>
                {scheme}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Custom Scheme Name (conditionally shown) */}
        {selectedSchemes?.includes("Others") && (
          <Form.Item
            name="custom_scheme"
            label="Custom Scheme Name"
            rules={[
              { required: true, message: "Please enter a custom scheme name" },
            ]}
          >
            <Input placeholder="Enter custom scheme name" />
          </Form.Item>
        )}

        <Form.Item
          name="bm_id"
          label="Branch Manager"
          rules={[
            { required: true, message: "Please select a Branch Manager" },
          ]}
          showSearch // Enable search
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          <Select placeholder="Select Branch Manager" style={{ width: "100%" }}>
            {bm.map((bm) => (
              <Option key={bm.id} value={bm.id}>
                {bm.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="closer_id"
          label="closer"
          rules={[{ required: true, message: "Please select a Closer" }]}
          showSearch // Enable search
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
        >
          <Select placeholder="Select Closer" style={{ width: "100%" }}>
            {user.map((closer) => (
              <Option key={closer.id} value={closer.id}>
                {closer.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      {/* Remarks */}
      <Form.Item name="remarks" label="Remarks">
        <Input.TextArea rows={4} placeholder="Enter remarks" />
      </Form.Item>

      {/* Save Button */}
      <Button type="primary" htmlType="submit">
        Save Changes
      </Button>
    </Form>
  );
};

export default EditDetails;
