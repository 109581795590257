import client from "./client";

// get applications
export const getAllApplications = async () => {
  try {
    const data = await client.get("/application");
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getOnePagerData = async () => {
  try {
    const data = await client.get("/application/getOnePagerData");
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const updateApplication = async (app_id, obj) => {
  try {
    const data = await client.post(`/application/${app_id}`, obj);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const statusUpdateApplication = async (app_id, obj) => {
  try {
    const data = await client.post(`/application/status/${app_id}`, obj);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
// get application by id
export const getApplicationByBdmId = async (id) => {
  try {
    const data = await client.get(`/application/${id}`);
    return data.data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
