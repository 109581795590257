import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Upload,
  Typography,
  Divider,
  message,
  TimePicker,
} from "antd";
import {
  UploadOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import { Radio } from "antd";
import axios from "axios";
import { getId } from "helper/EncrptionUtils";
import { useNavigate } from "react-router-dom";
import { getNaif } from "helper/EncrptionUtils";
import { allUser } from "api/users";
import { allBm } from "api/users";
const { Title, Text } = Typography;
const { Option } = Select;

const NAIFForm = () => {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: "",
    Executioner: "",
    email: "",
    customerType: "New",
    contactNo: "",
    projectSize: null,
    schemeName: [],
    commercial: null,
    documents: [],
    remarks: "", // Added remarks to the state
    customScheme: "", // Added custom scheme name state
    bdm_id: null,
    talktime: null,
    bm_id: null,
    closer_id: null,
  });
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [user, setUser] = useState([]);
  const [bm, setBM] = useState([]);
  // Handling input changes
  const handleInputChange = (name, value) => {
    setFormData({ ...formData, [name]: value });

    // Calculate project size * commercial when applicable
    if (name === "projectSize" || name === "commercial") {
      const newCalculatedValue =
        (name === "projectSize" ? value : formData.projectSize) *
        ((name === "commercial" ? value : formData.commercial) / 100);
    }
  };

  // Available scheme options
  const schemes = [
    "NAIF",
    "Working Capital",
    "HL",
    "LAP",
    "AHIDF",
    "NLM",
    "Machinery Loan",
    "Others", // "Others" option for custom scheme
  ];
  const handleFileUpload = (info) => {
    let newFiles = info.fileList.map((file) => file.originFileObj || file); // Ensure correct file reference

    setFormData((prevFormData) => {
      const existingFileNames = new Set(
        prevFormData.documents.map((file) => file.name)
      );
      const uniqueFiles = [];

      newFiles.forEach((file) => {
        if (file && !existingFileNames.has(file.name)) {
          existingFileNames.add(file.name);
          uniqueFiles.push(file);
        }
      });

      if (uniqueFiles.length !== newFiles.length) {
        message.error("Duplicate files are not allowed!");
      }

      return {
        ...prevFormData,
        documents: [...prevFormData.documents, ...uniqueFiles],
      };
    });
  };

  // File remove handler (ensures the correct file is removed)
  const handleFileRemove = (file) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      documents: prevFormData.documents.filter((doc) => doc.name !== file.name),
    }));
  };

  const naifAllUser = async () => {
    try {
      const users = await allUser();
      if (users.success) {
        setUser(users.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getBM = async () => {
    try {
      const users = await allBm();
      if (users.success) {
        setBM(users.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // Handling step transitions
  const handleNext = () => {
    const requiredFields = [
      "companyName",
      "contactNo",
      "projectSize",
      "schemeName",
      "closer_id",
      "bm_id",
      // "commercial",
    ];
    const emptyField = requiredFields.find((field) => !formData[field]);

    if (emptyField) {
      message.error("Please fill in all required fields before proceeding.");
      return;
    }

    if (getNaif() === "Admin" && !formData.bdm_id) {
      message.error("Please select the BDM");
      return;
    }

    setCurrentStep(currentStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async () => {
    try {
      // API Call 1: Register Company
      const formattedTalktime = formData.talktime
        ? formData.talktime.format("HH:mm:ss") // Convert Moment to string
        : null;

      setLoading(true);
      const registrationResponse = await axios.post(
        `${process.env.REACT_APP_URL}/api/application/register`,
        {
          company_name: formData.companyName,
          customerType: formData.customerType,
          Executioner: formData.Executioner,
          email: formData.email,
          contact: formData.contactNo,
          project_size: formData.projectSize,
          scheme_name: formData.schemeName,
          commercial: formData.commercial,
          remarks: formData.remarks,
          custom_scheme: formData.customScheme,
          talktime: formattedTalktime,
          bdm_id: getNaif() === "Admin" ? formData.bdm_id : getId(),
          bm_id: formData.bm_id,
          closer_id: formData.closer_id,
        }
      );

      const { app_id, success } = registrationResponse.data;

      if (!success) {
        message.error(registrationResponse.data.error);
        setLoading(false);
        return;
      }

      // API Call 2: Upload Documents
      const documentData = new FormData();
      documentData.append("app_id", app_id);

      formData.documents.forEach((file) => {
        documentData.append("files", file);
      });

      const uploadResponse = await axios.post(
        `${process.env.REACT_APP_URL}/api/application/upload`,
        documentData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (!uploadResponse.data.success) {
        message.error("Failed to upload documents. Please try again.");
      } else {
        message.success("Form submitted successfully!");
        setFormData({
          companyName: "",
          email: "",
          contactNo: "",
          projectSize: null,
          schemeName: [],
          commercial: null,
          documents: [],
          remarks: "",
          customScheme: "",
          customerType: "New",
          talktime: null,
          bm_id: null,
          closer_id: null,
        });
        setCurrentStep(1);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("An error occurred during submission.");
    } finally {
      setLoading(false); // Stop loading after submission completes
    }
  };

  // Reset custom scheme when "Others" is removed
  useEffect(() => {
    if (!formData.schemeName.includes("Others")) {
      setFormData((prevFormData) => ({ ...prevFormData, customScheme: "" }));
    }
  }, [formData.schemeName]);

  useEffect(() => {
    if (!getNaif()) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    naifAllUser();
    getBM();
  }, []);

  const inputStyle = {
    height: "48px",
    borderRadius: "8px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center", // Ensures vertical centering
  };

  return (
    <div className="mx-auto mt-6 max-w-7xl rounded-lg bg-gray-100 p-5 font-semibold shadow-lg">
      {currentStep === 1 && (
        <div>
          <Title level={3} className="mb-5 text-center">
            Registration Form
          </Title>
          <Divider />

          <Form layout="vertical">
            <div className="mb-5">
              <Radio.Group
                value={formData.customerType}
                onChange={(e) =>
                  handleInputChange("customerType", e.target.value)
                }
                buttonStyle="solid"
              >
                <Radio.Button value="Existing">Existing Customer</Radio.Button>
                <Radio.Button value="New">New Customer</Radio.Button>
              </Radio.Group>
            </div>

            <div className="grid grid-cols-1 gap-5 md:grid-cols-2">
              {/* Left Column */}
              <div>
                {/* Company Name */}
                <Form.Item label="Company Name" required>
                  <Input
                    placeholder="Enter company name"
                    value={formData.companyName}
                    onChange={(e) =>
                      handleInputChange("companyName", e.target.value)
                    }
                    style={inputStyle}
                  />
                </Form.Item>

                {/* <Form.Item label="Executioner Name" required>
                  <Input
                    placeholder="Enter Executioner name"
                    value={formData.Executioner}
                    onChange={(e) =>
                      handleInputChange("Executioner", e.target.value)
                    }
                    style={inputStyle}
                  />
                </Form.Item> */}

                {/* Email */}
                <Form.Item label="Email">
                  <Input
                    placeholder="Enter email"
                    value={formData.email}
                    onChange={(e) => handleInputChange("email", e.target.value)}
                    style={inputStyle}
                  />
                </Form.Item>

                <Form.Item label="Commercial (%)">
                  <InputNumber
                    placeholder="Enter commercial percentage"
                    value={formData.commercial}
                    max={100} // UI restriction
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace("%", "")}
                    onChange={(value) => {
                      if (value > 100) {
                        message.error(
                          "Commercial percentage cannot exceed 100%"
                        );
                        return;
                      }
                      handleInputChange("commercial", value);
                    }}
                    style={{
                      ...inputStyle,
                      width: "100%",
                    }}
                  />
                </Form.Item>

                {/* <Form.Item label="Projection Size * Commercial">
                  <Input
                    readOnly
                    placeholder="Projection Size * Commercial"
                    value={
                      formData.commercial && formData.projectSize
                        ? new Intl.NumberFormat("en-IN").format(
                            (formData.commercial * formData.projectSize) / 100
                          )
                        : ""
                    }
                    style={inputStyle}
                  />
                </Form.Item> */}
                <Form.Item label="Select BM" required>
                  <Select
                    placeholder="Select a BM"
                    value={formData.bm_id}
                    onChange={(value) => handleInputChange("bm_id", value)}
                    style={{ width: "100%", height: "48px" }}
                    showSearch // Enable search
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {bm.map((bm) => (
                      <Option key={bm.id} value={bm.id}>
                        {bm.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {getNaif() === "Admin" && (
                  <Form.Item label="Select BDM" required>
                    <Select
                      placeholder="Select a BDM"
                      value={formData.bdm_id}
                      onChange={(value) => handleInputChange("bdm_id", value)}
                      style={{ width: "100%", height: "48px" }}
                      showSearch // Enable search
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {user.map((bdm) => (
                        <Option key={bdm.id} value={bdm.id}>
                          {bdm.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </div>

              <div>
                <Form.Item label="Contact No" required>
                  <Input
                    placeholder="Enter contact number"
                    value={formData.contactNo}
                    onChange={(e) =>
                      handleInputChange("contactNo", e.target.value)
                    }
                    style={inputStyle}
                  />
                </Form.Item>
                <Form.Item label="Project Size (Amount)" required>
                  <InputNumber
                    placeholder="Enter project size"
                    value={formData.projectSize}
                    formatter={(value) =>
                      value
                        ? new Intl.NumberFormat("en-IN").format(value) // Proper Indian formatting
                        : ""
                    }
                    parser={
                      (value) => (value ? value.replace(/,/g, "") : "") // Remove commas on input
                    }
                    onChange={(value) =>
                      handleInputChange("projectSize", value)
                    }
                    style={{
                      ...inputStyle,
                      width: "100%",
                    }}
                  />
                </Form.Item>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Form.Item label="Scheme Name" required>
                    <Select
                      mode="multiple"
                      placeholder="Select one or more schemes"
                      value={formData.schemeName}
                      onChange={(value) => {
                        handleInputChange("schemeName", value);
                      }}
                      style={{
                        width: "100%", // Full width for select
                        height: "48px",
                      }}
                    >
                      {schemes.map((scheme, index) => (
                        <Option key={index} value={scheme}>
                          {scheme}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>

                  {/* Input for custom scheme name when "Others" is selected */}
                  {formData.schemeName.includes("Others") && (
                    <Form.Item label="Custom Scheme Name" required>
                      <Input
                        placeholder="Enter custom scheme name"
                        value={formData.customScheme}
                        onChange={(e) =>
                          handleInputChange("customScheme", e.target.value)
                        }
                        style={inputStyle}
                      />
                    </Form.Item>
                  )}
                  {/* <Form.Item label="Advanced Payment" className="text-gray-400">
                    <Input
                      readOnly
                      placeholder="Advanced Payment (50-50)"
                      value={
                        formData.commercial && formData.projectSize
                          ? new Intl.NumberFormat("en-IN").format(
                              (formData.commercial * formData.projectSize) /
                                100 /
                                2
                            )
                          : ""
                      }
                      style={inputStyle}
                    />
                  </Form.Item> */}
                  {/* <Form.Item name="talktime" className=" p-2">
                    <label>Talk Time (HH:mm:ss) :</label>
                    <TimePicker
                      name="talktime"
                      format="HH:mm:ss" // Format for hours, minutes, and seconds
                      placeholder="Select talktime"
                      style={{ width: "100%", height: "48px" }}
                      value={formData.talktime} // Controlled value from form
                      onChange={(value) => handleInputChange("talktime", value)}
                    />
                  </Form.Item> */}
                  <Form.Item label="Select closer" required>
                    <Select
                      placeholder="Select a closer"
                      value={formData.closer_id}
                      onChange={(value) =>
                        handleInputChange("closer_id", value)
                      }
                      style={{ width: "100%", height: "48px" }}
                      showSearch // Enable search
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {user.map((closer) => (
                        <Option key={closer.id} value={closer.id}>
                          {closer.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Remarks">
                    <Input.TextArea
                      placeholder="Enter remarks here"
                      value={formData.remarks}
                      onChange={(e) =>
                        handleInputChange("remarks", e.target.value)
                      }
                      style={{
                        ...inputStyle,
                        width: "100%",
                        height: "100px",
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>

          <div className="mt-5 flex justify-end">
            <Button
              type="primary"
              size="large"
              icon={<ArrowRightOutlined />}
              onClick={handleNext}
              style={{
                borderRadius: "8px",
                padding: "10px 20px",
                fontSize: "16px",
              }}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div>
          <Title level={3} className="mb-5 text-center">
            Upload Documents
          </Title>
          <Divider />

          {/* File Upload */}
          <Text className="mb-2 block text-lg font-medium">
            Drag and drop files below or click to upload
          </Text>
          <Form.Item>
            <Upload.Dragger
              multiple
              fileList={formData.documents.map((file) => ({
                uid: file.name,
                name: file.name,
              }))} // Prevents duplicates
              onChange={handleFileUpload}
              onRemove={handleFileRemove}
              beforeUpload={() => false} // Prevent auto-upload
            >
              <p className="ant-upload-drag-icon">
                <UploadOutlined style={{ fontSize: "24px" }} />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Ensure no duplicate file names are uploaded
              </p>
            </Upload.Dragger>
          </Form.Item>

          <Text>
            Uploaded Documents: <b>{formData.documents.length}</b> file(s)
          </Text>

          <div className="mt-5 flex justify-between">
            <Button
              type="default"
              size="large"
              icon={<ArrowLeftOutlined />}
              onClick={handlePrev}
              style={{
                borderRadius: "8px",
                padding: "10px 20px",
                fontSize: "16px",
              }}
            >
              Previous
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={handleSubmit}
              loading={loading} // Spinner appears when loading is true
              style={{
                borderRadius: "8px",
                padding: "10px 20px",
                fontSize: "16px",
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NAIFForm;
